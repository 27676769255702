import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      const currentUrl = window.location.href;
      window.location.href = `/authorization/refresh-token?postLoginRedirect=${encodeURIComponent(
        currentUrl
      )}`;
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
