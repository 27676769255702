<template>
  <div class="base-container">
    <base-circles />
    <div v-if="!fullScreen" class="navbar-container">
      <the-navbar>
        <the-user-menu
          :current-user="currentUser"
          :profile-picture="profilePicture"
          @signout="signOut"
        />
      </the-navbar>
    </div>
    <div class="homeoverview-container">
      <router-view />
    </div>
    <div v-if="!fullScreen" class="platform-footer">
      <span
        class="footer-content body-2"
        @click="$router.push({ name: 'Privacy' })"
      >
        {{ $t('privacy') }}
      </span>
      <span
        class="footer-content body-2"
        @click="$router.push({ name: 'TermsOfUse' })"
      >
        {{ $t('termsOfUse') }}
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, onMounted, ComputedRef, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import BaseCircles from '@/views/components/BaseCircles.vue';
import TheNavbar from '@/components/layout/TheNavbar.vue';
import TheUserMenu from '@/components/layout/TheUserMenu.vue';
import msal, { loginRedirect } from '@/services/msal/msal';
import { UserViewModel } from '@etp/etp-profile-client/axios';

export default {
  name: 'BasePage',
  components: {
    TheNavbar,
    TheUserMenu,
    BaseCircles
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const fullScreen = computed(() => store.getters['base/getFullScreen']);

    const currentUser: ComputedRef<UserViewModel> = computed(
      () => store.getters['base/getCurrentUser']
    );
    const profilePicture: ComputedRef<string> = computed(
      () => store.getters['base/getProfilePicture'] as string
    );
    const signOut = () => {
      msal.signOut().then(() => {
        router.push({ name: 'Login' });
      });
    };

    const validateUser = async () => {
      // TODO: remove when migration to cookies is completed
      if (!currentUser.value || !msal.isAuthenticated()) {
        await loginRedirect();
      }
      if (!currentUser.value.eulaAcceptedDate) {
        router.push({ name: 'Eula' });
      }
    };

    onBeforeMount(async () => {
      await store.dispatch('base/fetchCurrentUser');
      await validateUser();
    });

    onMounted(async () => {
      store.commit('base/SET_FULL_SCREEN', false);
      await store.dispatch('base/fetchProfilePicture');
    });

    return { signOut, currentUser, profilePicture, fullScreen };
  }
};
</script>
<style scoped lang="scss">
.base-container {
  background: $etp-background;
  background-size: cover;
  background-attachment: fixed;
  height: auto;
  min-height: 100vh;
  width: 100%;
  position: absolute;
}

.navbar-container {
  position: absolute;
  width: 100%;
  height: 78px;
  z-index: 1;
}

.homeoverview-container {
  display: flex;
  height: 100%;
}

.platform-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 0.75em 2em;
  line-height: 14px;
  z-index: 1;

  > .footer-content {
    margin: 0 12px;
    cursor: pointer;
    color: $etp-deloitte-blue-7;
  }
}
</style>
