import { createAuthorisationQueryClient } from '@/services/api';
import {
  PermissionRequirementModel,
  PermissionRequirementResultModel
} from '@etp/etp-authorization-client/axios';

export async function checkPermissions(
  permissionRequirements: PermissionRequirementModel[]
): Promise<PermissionRequirementResultModel[]> {
  let client = createAuthorisationQueryClient();
  const { data, status } = await client.queryMyPermissions(
    permissionRequirements
  );
  if (status == 200) {
    return data;
  }
  return new Array<PermissionRequirementResultModel>();
}

export async function checkAnyPermission(
  permissionRequirements: PermissionRequirementModel[]
) {
  let client = createAuthorisationQueryClient();
  const { data, status } = await client.queryMyPermissions(
    permissionRequirements
  );
  if (status == 200 && data.length) {
    return !!data.filter(el => el.allowed).length;
  }
  return false;
}

export async function checkAllPermissions(
  permissionRequirements: PermissionRequirementModel[]
) {
  let client = createAuthorisationQueryClient();
  const { data, status } = await client.queryMyPermissions(
    permissionRequirements
  );
  if (status == 200 && data.length) {
    return !data.filter(el => !el.allowed).length;
  }
  return false;
}
