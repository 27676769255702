import {
  createAuthorisationUsersClient,
  createProfileApiClient
} from '@/services/api';
import {
  CreateOrUpdateUserModel,
  RoleAssignmentModel,
  UserDetailsModel as AuthorizationUserModel,
  UserModel,
  UserModelPage
} from '@etp/etp-authorization-client/axios';
import {
  CreateOrUpdateUserDto,
  UserViewModel as ProfileUserModel
} from '@etp/etp-profile-client/axios';
import { ActionContext, Module } from 'vuex';

interface UsersState {
  loading: boolean;
  authorizationUser: AuthorizationUserModel;
  pageUsers: Array<UserModel>;
  usersPageNumber: number;
  usersPageSize: number;
  profileUser: ProfileUserModel;
  users: Array<UserModel>;
  usersCount: number;
  userRoleAssignments: Array<RoleAssignmentModel>;
}

class UsersModule implements Module<UsersState, any> {
  namespaced = true;
  state = {
    loading: false,
    authorizationUser: {} as AuthorizationUserModel,
    pageUsers: [],
    usersPageNumber: 1,
    usersPageSize: 10,
    profileUser: {} as ProfileUserModel,
    users: [],
    usersCount: 0,
    userRoleAssignments: []
  };
  getters = {
    getUsers: (state: UsersState): Array<UserModel> => {
      return state.users;
    },
    getAuthorizationUser: (state: UsersState) => {
      return state.authorizationUser;
    },
    getPageUsers: (state: UsersState) => {
      return state.pageUsers;
    },
    getUsersPageNumber: (state: UsersState): number => {
      return state.usersPageNumber;
    },
    getUsersPageSize: (state: UsersState): number => {
      return state.usersPageSize;
    },
    getProfileUser: (state: UsersState) => {
      return state.profileUser;
    },
    getUsersCount: (state: UsersState): number => {
      return state.usersCount;
    },
    getUserRoleAssignments: (state: UsersState) => {
      return state.authorizationUser.roleAssignments;
    }
  };
  actions = {
    async fetchPageUsers(context: ActionContext<UsersState, any>) {
      context.state.loading = true;
      let client = createAuthorisationUsersClient();
      const { data, status } = await client.listUsers(
        context.state.usersPageNumber,
        context.state.usersPageSize
      );

      if (status == 200) {
        context.commit('SET_PAGINATED_USERS', data);
      } else {
        context.commit('SET_PAGINATED_USERS', {} as UserModelPage);
      }
      context.state.loading = false;
    },
    async fetchAllUsers(context: ActionContext<UsersState, any>) {
      context.state.loading = true;
      let client = createAuthorisationUsersClient();
      const { data, status } = await client.listUsers();
      if (status == 200) {
        context.commit('SET_USERS', data);
      } else {
        context.commit('SET_USERS', {} as UserModelPage);
      }
      context.state.loading = false;
    },
    async fetchAuthorizationUser(
      context: ActionContext<UsersState, any>,
      email: string
    ) {
      let client = createAuthorisationUsersClient();
      const { data, status } = await client.getUserDetails(email);
      if (status == 200) {
        context.commit('SET_AUTHORIZATION_USER', data);
      } else {
        context.commit('SET_AUTHORIZATION_USER', {} as AuthorizationUserModel);
      }
    },
    async fetchProfileUser(
      context: ActionContext<UsersState, any>,
      email: string
    ) {
      let client = createProfileApiClient();
      const { data, status } = await client.getUser(email);
      if (status == 200) {
        context.commit('SET_PROFILE_USER', data);
      } else {
        context.commit('SET_PROFILE_USER', {} as ProfileUserModel);
      }
    },
    async createOrUpdateUser(
      context: ActionContext<UsersState, any>,
      payload: {
        email: string;
        profileModel: CreateOrUpdateUserDto | undefined;
        authorizationModel: CreateOrUpdateUserModel | undefined;
      }
    ) {
      if (payload.profileModel) {
        let profileClient = createProfileApiClient();
        await profileClient.createOrUpdateUser(
          payload.email,
          payload.profileModel
        );
      }
      if (payload.authorizationModel) {
        let authorizationClient = createAuthorisationUsersClient();
        await authorizationClient.createOrUpdateUser(
          payload.email,
          payload.authorizationModel
        );
      }
    }
  };
  mutations = {
    SET_USERS(state: UsersState, payload: UserModelPage) {
      state.users = payload.results ?? new Array<UserModel>();
      state.usersCount = payload.meta?.totalItems ?? 0;
    },
    SET_PAGINATED_USERS(state: UsersState, payload: UserModelPage) {
      state.pageUsers = payload.results ?? new Array<UserModel>();
      state.usersCount = payload.meta?.totalItems ?? 0;
    },
    SET_USERS_PAGE(state: UsersState, payload: number) {
      state.usersPageNumber = payload;
    },
    SET_AUTHORIZATION_USER(state: UsersState, payload: AuthorizationUserModel) {
      state.authorizationUser = payload;
    },
    SET_PROFILE_USER(state: UsersState, payload: ProfileUserModel) {
      state.profileUser = payload;
    }
  };
}

export default new UsersModule();
