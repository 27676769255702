import { ActionContext, Module } from 'vuex';
import {
  ApplicationDetailsModel,
  ApplicationModel,
  ApplicationModelPage,
  CreateOrUpdateApplicationModel
} from '@etp/etp-authorization-client/axios';
import { createAuthorisationApplicationsClient } from '@/services/api';

interface ApplicationsState {
  loading: boolean;
  applications: Array<ApplicationModel>;
  applicationDetails: ApplicationDetailsModel;
  pageApplications: Array<ApplicationModel>;
  applicationsPageNumber: number;
  applicationsPageSize: number;
  applicationsCount: number;
}

class ApplicationsModule implements Module<ApplicationsState, any> {
  namespaced = true;
  state = {
    loading: false,
    applications: [],
    applicationDetails: {} as ApplicationDetailsModel,
    pageApplications: [],
    applicationsPageNumber: 1,
    applicationsPageSize: 10,
    applicationsCount: 0
  };
  getters = {
    getApplications: (state: ApplicationsState) => {
      return state.applications;
    },
    getApplicationDetails: (state: ApplicationsState) => {
      return state.applicationDetails;
    },
    getApplicationRoleAssignments: (state: ApplicationsState) => {
      return state.applicationDetails.roleAssignments;
    },
    getPageApplications: (
      state: ApplicationsState
    ): Array<ApplicationModel> => {
      return state.pageApplications;
    },
    getApplicationsPageNumber: (state: ApplicationsState): number => {
      return state.applicationsPageNumber;
    },
    getApplicationsPageSize: (state: ApplicationsState): number => {
      return state.applicationsPageSize;
    },
    getApplicationsCount: (state: ApplicationsState): number => {
      return state.applicationsCount;
    }
  };
  actions = {
    async fetchPageApplications(
      context: ActionContext<ApplicationsState, any>
    ) {
      context.state.loading = true;
      let client = createAuthorisationApplicationsClient();

      const { status, data } = await client.listApplications(
        context.state.applicationsPageNumber,
        context.state.applicationsPageSize
      );

      if (status >= 200 && status <= 299) {
        context.commit('SET_PAGINATED_APPLICATIONS', data);
      } else {
        context.commit(
          'SET_PAGINATED_APPLICATIONS',
          {} as ApplicationModelPage
        );
      }
      context.state.loading = false;
    },
    async fetchAllApplications(context: ActionContext<ApplicationsState, any>) {
      context.state.loading = true;
      let client = createAuthorisationApplicationsClient();

      const { status, data } = await client.listApplications();

      if (status >= 200 && status <= 299) {
        context.commit('SET_APPLICATIONS', data);
      } else {
        context.commit('SET_APPLICATIONS', {} as ApplicationModelPage);
      }
      context.state.loading = false;
    },
    async fetchApplicationDetails(
      context: ActionContext<ApplicationsState, any>,
      aadObjectId: string
    ) {
      let client = createAuthorisationApplicationsClient();
      const { data, status } = await client.getApplicationDetails(aadObjectId);
      if (status == 200) {
        context.commit('SET_APPLICATION_DETAILS', data);
      } else {
        context.commit(
          'SET_APPLICATION_DETAILS',
          {} as ApplicationDetailsModel
        );
      }
    },
    async createOrUpdateApplication(
      context: ActionContext<ApplicationsState, any>,
      payload: { aadObjectId: string; model: CreateOrUpdateApplicationModel }
    ) {
      let client = createAuthorisationApplicationsClient();
      await client.createOrUpdateApplication(
        payload.aadObjectId,
        payload.model
      );
    },
    async deleteApplication(
      context: ActionContext<ApplicationsState, any>,
      application: ApplicationModel
    ) {
      let client = createAuthorisationApplicationsClient();
      await client.deleteApplication(application.aadObjectId);
    }
  };
  mutations = {
    SET_APPLICATIONS(state: ApplicationsState, payload: ApplicationModelPage) {
      state.applications = payload.results ?? new Array<ApplicationModel>();
      state.applicationsCount = payload.meta?.totalItems ?? 0;
    },
    SET_PAGINATED_APPLICATIONS(
      state: ApplicationsState,
      payload: ApplicationModelPage
    ) {
      state.pageApplications = payload.results ?? new Array<ApplicationModel>();
      state.applicationsCount = payload.meta?.totalItems ?? 0;
    },
    SET_APPLICATION_DETAILS(
      state: ApplicationsState,
      payload: ApplicationDetailsModel
    ) {
      state.applicationDetails = payload;
    },
    SET_APPLICATIONS_PAGE(state: ApplicationsState, payload: number) {
      state.applicationsPageNumber = payload;
    }
  };
}

export default new ApplicationsModule();
